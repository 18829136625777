<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import router from "./router/index.js";
import axios from 'axios';

export default {
  name: "App",
  components: {
    Header,
  },
  created: function () {
    document.title = "RMM - Boite à sites";
    router.afterEach((to, from, next) => {
      if (!(to.name === "Home") && !localStorage.getItem("token")) {
        this.$router.push("/");
		return;
      }

	  if (localStorage.getItem("token")) {
		axios
        .get(`${this.$API_URL}/verify/`)
        .then(function (response) {
          // handle success
          console.log("Toujours connecté");
        })
        .catch((error) => {
          // handle error
          const { response } = error;
          const { request, ...errorObject } = response; // take everything but 'request'
          if (errorObject.status === 401) {
            console.log("Utilisateur déconnecté");
			localStorage.removeItem('token');
            this.$router.push("/");
          }
        });
	  }
 
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  // height: 100%;
}

body {
  font-size: 1.2rem;
  // line-height: 1.5;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font-family: "Red Hat Display", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

textarea,
input,
button {
  font-family: "Red Hat Display", sans-serif;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input {
  min-width: 0;
  height: 30px;
  display: inline-flex;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 0 12px;
  background: #fff;
  color: #000;
  line-height: normal;
  outline: none;
  transition: border-color 0.15s ease;
}
input:focus {
  border-color: #666;
}

html,
body,
#app {
  // height: 100%;
  width: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button {
  border: 0px;
  background-color: deepskyblue;
  color: white;
  padding: 5px 15px;
  margin-left: 10px;
}

.btn {
  display: inline-block;
  padding: 2px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 10px;
  text-decoration: none;
  max-width: 300px;

  &.blue {
    box-shadow: 0 6px 20px rgba(0, 118, 255, 0.23);
    background-color: rgb(0, 117, 252);
    color: white;
    transition: all 0.1s ease-in-out;
  }

  &.orange {
    box-shadow: 0 6px 20px rgba(255, 153, 0, 0.23);
    background-color: rgb(255, 136, 0);
    color: white;
    transition: all 0.1s ease-in-out;
  }

  &.red {
    box-shadow: 0 6px 20px rgba(255, 0, 0, 0.23);
    background-color: rgb(252, 16, 16);
    color: white;
    transition: all 0.1s ease-in-out;
    font-weight: 600;
  }

  &.green {
    background-color: #03a000;
    color: white;
    transition: all 0.1s ease-in-out;
    font-weight: 600;
  }

  &.yellow {
    background-color: rgb(243, 171, 16);
    color: white;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    transform: scale(1.05);
    opacity: 0.8;
    color: white;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25));
  }
  &:focus {
    color: #fff;
    outline: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
  }

  &:active:focus,
  &:hover:active,
  &:active {
    outline: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    outline-offset: none;
    filter: none;
  }

  &:active:focus &:hover:active,
  &:active {
    transform: scale(0.9);
    opacity: 1.1;
    color: white;
    outline: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    outline-offset: none;
    filter: none;
  }
}
</style>
