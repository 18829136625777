import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import axios from 'axios';

axios.defaults.headers.common['x-access-token'] = localStorage.getItem('token');

// Vue.prototype.$API_URL = 'http://localhost:3000';
// Vue.prototype.$DOCUMENTROOT_URL = 'http://localhost:5555/sites';

Vue.prototype.$API_URL = 'https://fleurdepapier.alwaysdata.net/api';
Vue.prototype.$DOCUMENTROOT_URL = 'https://expositionsvirtuelles.fr';


// Vue.prototype.$DOCUMENTROOT_URL = 'https://fleurdepapier.alwaysdata.net/sites';

import { Notification, Message, MessageBox, Loading } from 'element-ui'
Vue.prototype.$notify = Notification;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/help',
    name: 'Help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue')
  },
  {
    path: '/sites',
    name: 'ListSites',
    component: () => import(/* webpackChunkName: "sites" */ '../views/ListSites.vue')
  },
  {
    path: '/homepage/',
    name: 'Homepage',
    component: () => import(/* webpackChunkName: "homepage" */ '../views/EditionHomepage.vue')
  },
  {
    path: '/sites/:sortedName',
    name: 'EditionSite',
    props: true,
    component: () => import(/* webpackChunkName: "EditionSite" */ '../views/EditionSite.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
