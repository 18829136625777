<template>
	<header class="header">
		<span><b>RMM</b>| Boite à sites</span>
		<ul class="right">

			<li><router-link class="btn blue" to="/sites">Tous les sites</router-link></li>
			<li><router-link class="btn blue" to="/homepage">Portail</router-link></li>
			<!-- <li><router-link class="btn blue" to="/help">Aide</router-link></li> -->
			<li><a class="btn blue" target="_blank" href="https://fleurdepapier.alwaysdata.net/docs/#/">Aide</a></li>
			<li><a class="btn red" @click="handleDeconnection" href="#">Se déconnecter</a></li>
		</ul>
	</header>
</template>

<script>
export default {
	name: 'Header',
	methods: {
		handleDeconnection: function () {
			localStorage.removeItem('token');
			this.$router.push('/');
		}
	}
};
</script>

<style lang="scss" scoped>

.header {
	width: 100%;
	height:90px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #24292e;
	color:#fff;

    span {
	    margin-left: 45px;
        font-size: 30px;
    }

    .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    li {
        list-style: none;
    }
}
</style>
