<template>
  <section>
    <div class="container">
      <div class="user signinBx">
        <div class="imgBx">
          <img src="../assets/home.jpg" alt />
        </div>
        <div class="formBx">
          <p v-show="badAuth" class="badAuth">Identifiant ou mot de passe incorrect.</p>
          <form @submit.prevent="handleSubmit" class="formLog" action>
            <h2>Se connecter</h2>
            <input type="text" name="username" v-model="username" placeholder="Nom d'utilisateur" />
            <input type="password" name="pass" v-model="password" placeholder="Mot de passe" />
            <input type="submit" name="submit" value="Se connecter" />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";


export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      badAuth: false,
    }
  },
  methods: {
    toggleForm(e) {
      const container = document.querySelector(".container");
      container.classList.toggle("active");
    },
    async handleSubmit() {
      await axios.post(`${this.$API_URL}/login`, {
        username: this.username,
        password: this.password,
      })
      .then((response) => {
        // handle success
        localStorage.setItem('token', response.data.token);
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem('token');
        this.$router.push('/sites');

      })
      .catch( (error) => {
        // handle error
        const { response } = error;
        const { request, ...errorObject } = response;
        errorObject.status === 400 ? this.badAuth = true : console.log(error);
      });

    }
  }
}

</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: #f8dd30;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .badAuth {
    color: red;
    font-size: 1.2rem;
    margin-bottom: 0px;
  }
}

section .container {
  position: relative;
  width: 800px;
  height: 500px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}


section .container .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

section .container .user .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #ff0;
  transition: 0.5s;
}

section .container .user .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section .container .user .formBx {
  position: relative;
  width: 50%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  transition: 0.5s;
}

section .container .user .formBx form h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
}

section .container .user .formBx form input {
  position: relative;
  width: 100%;
  padding: 10px;
  background: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 8px 0;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
}

section .container .user .formBx form input[type="submit"] {
  max-width: fit-content;
  line-height: .8;
  background: #677eff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.5s;
}

section .container .user .formBx form .signup {
  position: relative;
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #555;
  text-transform: uppercase;
  font-weight: 300;
}

section .container .user .formBx form .signup a {
  font-weight: 600;
  text-decoration: none;
  color: #677eff;
}

section .container .signupBx {
  pointer-events: none;
}

section .container.active .signupBx {
  pointer-events: initial;
}

section .container .signupBx .formBx {
  left: 100%;
}

section .container.active .signupBx .formBx {
  left: 0;
}

section .container .signupBx .imgBx {
  left: -100%;
}

section .container.active .signupBx .imgBx {
  left: 0%;
}

section .container .signinBx .formBx {
  left: 0%;
}

section .container.active .signinBx .formBx {
  left: 100%;
}

section .container .signinBx .imgBx {
  left: 0%;
}

section .container.active .signinBx .imgBx {
  left: -100%;
}

@media (max-width: 991px) {
  section .container {
    max-width: 400px;
  }

  section .container .imgBx {
    display: none;
  }

  section .container .user .formBx {
    width: 100%;
  }
}
</style>